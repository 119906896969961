html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: 3px solid blue;
}

:focus-visible {
  outline: 3px solid blue;
}

:focus:not(:focus-visible) {
  outline: 0;
}

/* Google charts custom tooltip style */
.google-visualization-tooltip {
  border: 1px solid #e6eaf0;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.google-visualization-tooltip div {
  padding: 1rem;
  font-size: 1.4rem;
}

.google-visualization-tooltip h4 {
  font-size: 1.4rem;
  line-height: 2;
  margin: 0 0 0.25rem 0;
}
